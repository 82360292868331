import { React } from "react";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import { Tag, Percent } from "@mui/icons-material";
import DataCard from "components/DataCard";
import { useGetScoresQuery } from "state/api";
import { useOutletContext } from "react-router-dom";
import Header from "components/Header";

function Scores() {
  const [isSidebarOpen, setIsSidebarOpen] = useOutletContext();
  const { data, isLoading } = useGetScoresQuery();
  console.log("data", data);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  return (
    <Box
      display="flex"
      max-width="1100px"
      pl="20px"
      flexDirection="column"
      alignItems="center"
      padding="20px"
    >
      <Header
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        title="Test Scores"
      />

      {data || !isLoading ? (
      <Box width="100%">
        <Box
          display="flex"
          width={isNonMobile ? "100%" : "100%"}
          flexDirection={isNonMobile ? "row" : "column"}
          gap="10px"
          alignItems="center"
          justifyContent="space-between"
          padding="10px var(--spacing-none, 0px) var(--spacing-2xl, 20px) var(--spacing-none, 0px)"
        >
          <DataCard
            icon={<Tag sx={{ color: "#fff" }} />}
            title="Number of Profiles with GMAT"
            value={data.scores.count_GMAT}
          />
          <DataCard
            icon={<Percent sx={{ color: "#fff" }} />}
            title="Average GMAT"
            value={data.scores.averageGMAT}
          />
        </Box>
        <Box
          display="flex"
          width={isNonMobile ? "100%" : "100%"}
          flexDirection={isNonMobile ? "row" : "column"}
          gap="10px"
          alignItems="center"
          justifyContent="space-between"
          padding="10px var(--spacing-none, 0px) var(--spacing-2xl, 20px) var(--spacing-none, 0px)"
        >
          <DataCard
            icon={<Tag sx={{ color: "#fff" }} />}
            title="Number of Profiles with TOEFL"
            value={data.scores.count_TOEFL}
          />
          <DataCard
            icon={<Percent sx={{ color: "#fff" }} />}
            title="Average TOEFL"
            value={data.scores.averageTOEFL}
          />
        </Box>
      </Box>
      ) : (
        <CircularProgress sx={{ color: "white" }} />
      )}

    </Box>
  );
}

export default Scores;
