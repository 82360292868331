import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { theme } from "theme";
import Layout from "scenes/layout";
import Awards from "scenes/awards";
import Certifications from "scenes/certifications";
import Education from "scenes/education";
import Languages from "scenes/languages";
import Patents from "scenes/patents";
import Organizations from "scenes/organizations";
import Projects from "scenes/projects";
import Internships from "scenes/internships";
import Skills from "scenes/skills";
import Volunteering from "scenes/volunteering";
import Publications from "scenes/publications";
import Positions from "scenes/positions";
import Hirings from "scenes/hirings";
import Scores from "scenes/scores";
import Home from "scenes/home";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route element={ <Layout /> }>
              <Route path="/" element={ <Navigate to ="/home" replace />} />
              <Route path="/awards" element={ <Awards /> } />
              <Route path="/certifications" element={ <Certifications /> } />
              <Route path="/education" element={ <Education /> } />
              <Route path="/languages" element={ <Languages /> } />
              <Route path="/hirings" element={ <Hirings /> } />
              <Route path="/organizations" element={ <Organizations /> } />
              <Route path="/patents" element={ <Patents /> } />
              <Route path="/projects" element={ <Projects /> } />
              <Route path="/positions" element={ <Positions /> } />
              <Route path="/internships" element={ <Internships /> } />
              <Route path="/publications" element={ <Publications /> } />
              <Route path="/test-scores" element={ <Scores /> } />
              <Route path="/volunteering" element={ <Volunteering /> } />
              <Route path="/skills" element={ <Skills /> } />
            </Route>
            <Route path="/home" element={ <Home /> } />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
