import React from "react";
import { Box, Typography, Button } from "@mui/material";

function Hero() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      height="100%"
      sx={{ backgroundColor: "#fff" }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        pt="var(--spacing-9xl, 96px)"
        maxWidth="var(--container-max-width-desktop, 1280px)"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="var(--spacing-6xl, 48px)"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="var(--spacing-3xl, 24px)"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="var(--spacing-xl, 16px)"
            >
              <Box
                display="flex"
                gap="var(--spacing-lg, 12px)"
                justifyContent="center"
                maxWidth="235px"
                borderRadius="var(--radius-full, 9999px)"
                border="1.5px solid #1369DD"
                p="var(--spacing-xs, 2px) 10px var(--spacing-xs, 2px) var(--spacing-xs, 4px)"
                background="#FFF"
                sx={{
                '&:hover': {
                  backgroundColor: "rgba(19, 105, 221, 0.2)"
              }}}>
                <a href="/awards" style={{display: "flex", textDecoration: "none", gap: "12px"}}>
                <Box
                  display="flex"
                  alignItems="center"
                  borderRadius="var(--radius-full, 9999px)"
                  border="1.5px solid #1369DD"
                  p="var(--spacing-xxs, 2px) 10px;"
                >
                  <Typography fontSize="10px" color="#1369DD">
                    New Report
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="var(--spacing-xs, 4px)"
                >
                  <Typography fontSize="10px" color="#1369DD">
                    Explore MBB Germany
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M3.83325 7.99998H13.1666M13.1666 7.99998L8.49992 3.33331M13.1666 7.99998L8.49992 12.6666"
                      stroke="#1369DD"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Box>
                </a>
              </Box>

              <Typography className="headline" p="0px 10px">
                Enhanced career analytics insights
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography className="subheader" p="0px 30px">
                Enhance your career and your applications by benchmarking peers
                from the most sought after employers.
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" gap="12px">
            <Button
              variant="contained"
              size="large"
              href="https://community.careeriq.work/"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                border: "1px solid #D0D5DD",
                color: "#263238",
                textTransform: "none",
                '&:hover': {
                  border: '1px solid #1369DD !important',
                  color: 'white',
                  backgroundColor: '#1369DD'
              }
              }}
            >
              Join our community
            </Button>
            <Button
              variant="contained"
              size="large"
              href="#datasets"
              sx={{
                textTransform: 'none',
                backgroundColor: '#1369DD',
                borderRadius: "8px",
                border: '1px solid #1369DD !important', // Default state with a transparent border.
                transition: 'all 0.3s ease', // Smooth transition for all properties.
                '&:hover': {
                  border: '1px solid #1369DD !important',
                  color: '#1369DD',
                  backgroundColor: 'white'
              }}}>
              Explore data
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Hero;
