import { React } from "react";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import { Tag, Functions, Percent } from "@mui/icons-material";
import DataCard from "components/DataCard";
import { DataGrid } from "@mui/x-data-grid";
import { useGetPositionsQuery } from "state/api";
import { useOutletContext } from "react-router-dom";
import Header from "components/Header";
import SubHeader from "components/SubHeader";

function Positions() {
  const [isSidebarOpen, setIsSidebarOpen] = useOutletContext();
  const { data, isLoading } = useGetPositionsQuery();
  const rows = data?.positions ? Object.keys(data.positions.allRoles).map((positionName, index) => ({
    id: index,
    position: positionName,
    employees: data.positions.allRoles[positionName].total,
    duration: data.positions.allRoles[positionName].averageDurationMonths.toFixed(2),
  })) : [];
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const columns = [
    {
      field: "position",
      headerName: "Position",
      flex: 1,
    },
    {
      field: "employees",
      headerName: "Number of Employees",
      flex: 1,
    },
    {
      field: "duration",
      headerName: "Duration of Average Stay (Months)",
      flex: 1,
    },
  ];

  return (
    <Box
      display="flex"
      max-width="1100px"
      pl="20px"
      flexDirection="column"
      alignItems="center"
      padding="20px"
    >
      <Header
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        title="Positions"
      />

      {data || !isLoading ? (
        <Box
          display="flex"
          width={isNonMobile ? "100%" : "100%"}
          flexDirection={isNonMobile ? "row" : "column"}
          gap="10px"
          alignItems="center"
          justifyContent="space-between"
          padding="10px var(--spacing-none, 0px) var(--spacing-2xl, 20px) var(--spacing-none, 0px)"
        >
          <DataCard
            icon={<Tag sx={{ color: "#fff" }} />}
            title="McKinsey"
            value={data.positions.mckinseyTotal}
          />
          <DataCard
            icon={<Percent sx={{ color: "#fff" }} />}
            title="Boston Consulting Group"
            value={data.positions.bcgTotal}
          />
          <DataCard
            icon={<Functions sx={{ color: "#fff" }} />}
            title="Bain"
            value={data.positions.bainTotal}
          />
        </Box>
      ) : (
        <CircularProgress sx={{ color: "white" }} />
      )}

      <SubHeader title="Career Progression and Employment Stats" />

      {data || !isLoading ? (
        <Box
          display="flex"
          width="100%"
          gap="10px"
          alignItems="center"
          justifyContent="space-between"
          padding="0px var(--spacing-none, 0px) var(--spacing-2xl, 20px) var(--spacing-none, 0px)"
        >
          <Box sx={{ width: "100%" }}>
            <DataGrid
              loading={isLoading || !data}
              sx={{ color: "#fff", border: "none" }}
              getRowId={(row) => row.position}
              rows={rows || []}
              columns={columns}
              hideFooter={true}
              disableColumnFilter={true}
            />
          </Box>
        </Box>
      ) : (
        <CircularProgress sx={{ color: "white" }} />
      )}
    </Box>
  );
}

export default Positions;
