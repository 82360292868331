import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "assets/logoCareerIQ.svg";
import { Link } from "react-router-dom";
import {
  TextField,
  FormControl,
  Typography,
  IconButton,
  Toolbar,
  Box,
  AppBar,
  Modal,
  MenuItem,
  Button,
  Container,
  CircularProgress,
} from "@mui/material"; // Added TextField and FormControl to the imports
import { Close } from "@mui/icons-material";

const pages = ["Home", "Datasets", "Datapoints", "Full Access"];

function NavBar() {
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const formHandler = async () => {
    setLoading(true); // Show the loading spinner

    const companyName = document.getElementById("company-name").value;
    const email = document.getElementById("email").value;

    try {
      const response = await fetch("/general/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ companyName, email }),
      });

      if (response.status === 200) {
        setMessage("Email sent successfully");
      } else {
        setMessage(
          "Error sending email - please text us at careeriq@gmail.com"
        );
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Hide the loading spinner
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #D0D5DD",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <AppBar position="static" className="homepage">
      <Container maxWidth="xl" sx={{ marginTop: "15px"}}>
        <Toolbar disableGutters>
          <Box
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
            }}
          >
            <img src={logo} alt="CareerIQ Logo" />
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                color: "black",
              }}
            >
              {pages.map((page) => {
                if (page === "Full Access") {
                  return (
                    <Button
                      key={page}
                      onClick={handleOpenModal}
                      sx={{
                        my: 2,
                        display: "block",
                        fontSize: "16px",
                        textTransform: "none !important",
                        fontWeight: "600",
                      }}
                      className="menuitem"
                    >
                      {page}
                    </Button>
                  );
                }
                return (
                  <Button
                    key={page}
                    sx={{
                      my: 2,
                      display: "block",
                      fontSize: "16px",
                      textTransform: "none !important",
                      fontWeight: "600",
                    }}
                    className="menuitem"
                    component={page === "Home" ? Link : "a"}
                    to={page === "Home" ? "/home" : undefined}
                    href={
                      page !== "Home" ? `#${page.toLowerCase()}` : undefined
                    }
                  >
                    {page}
                  </Button>
                );
              })}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              textDecoration: "none",
            }}
          >
            <img src={logo} alt="CareerIQ Logo" />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              gap: "var(--spacing-4xl, 32px)",
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => {
              if (page === "Full Access") {
                return (
                  <MenuItem key={page} onClick={handleOpenModal}>
                    <Typography className="menuitem" textAlign="center">
                      {page}
                    </Typography>
                  </MenuItem>
                );
              }
              return (
                <MenuItem
                  key={page}
                  onClick={handleCloseNavMenu}
                  component={page === "Home" ? Link : "a"}
                  to={page === "Home" ? "/home" : undefined}
                  href={page !== "Home" ? `#${page.toLowerCase()}` : undefined}
                >
                  <Typography className="menuitem" textAlign="center">
                    {page}
                  </Typography>
                </MenuItem>
              );
            })}
          </Box>
          <Button
            variant="contained"
            href="https://community.careeriq.work/"
            sx={{
              textTransform: "none",
              border: "1px solid black !important",
              transition: "all 0.3s ease",
              borderRadius: "8px",
              "&:hover": {
                border: "1px solid #1369DD !important",
                color: "#1369DD",
                backgroundColor: "white",
              },
            }}
          >
            Join Community
          </Button>
        </Toolbar>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="full-access-modal-title"
          aria-describedby="full-access-modal-description"
        >
          <Box sx={style}>
            <IconButton
              sx={{ position: "absolute", right: 0, top: 0 }}
              onClick={handleCloseModal}
            >
              <Close />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Request full access to your database
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Let us know your email and we will be in touch.
            </Typography>
            {message && (
              <Typography
                sx={{
                  mt: 2,
                  color:
                    message === "Email sent successfully" ? "green" : "red",
                }}
              >
                {message}
              </Typography>
            )}
            <FormControl
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <TextField
                id="company-name"
                label="Company name"
                variant="outlined"
              />
              <TextField id="email" label="E-Mail" variant="outlined" />
              <Button
                variant="contained"
                size="large"
                onClick={formHandler}
                disabled={loading}
                endIcon={
                  loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : null
                }
                sx={{
                  backgroundColor: "#1369DD",
                  boxShadow: "none",
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                Send
              </Button>
            </FormControl>
          </Box>
        </Modal>
      </Container>
    </AppBar>
  );
}

export default NavBar;
