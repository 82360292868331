import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  reducerPath: "adminApi",
  tagTypes: [
    "Awards",
    "Certifications",
    "Education",
    "Languages",
    "Hirings",
    "Organizations",
    "Patents",
    "Internships",
    "Positions",
    "Projects",
    "Publications",
    "Scores",
    "Volunteering",
    "Skills"
  ],
  endpoints: (build) => ({
    getAwards: build.query({
      query: () => "/awards",
      providesTags: ["Awards"],
    }),
    getCertifications: build.query({
      query: () => "/certifications",
      providesTags: ["Certifications"],
    }),
    getEducation: build.query({
      query: () => "/education",
      providesTags: ["Education"],
    }),
    getLanguages: build.query({
      query: () => "/languages",
      providesTags: ["Languages"],
    }),
    getHirings: build.query({
      query: () => "/hirings",
      providesTags: ["Hirings"],
    }),
    getOrganizations: build.query({
      query: () => "/organizations",
      providesTags: ["Organizations"],
    }),
    getPatents: build.query({
      query: () => "/patents",
      providesTags: ["Patents"],
    }),
    getInternships: build.query({
      query: () => "/internships",
      providesTags: ["Internships"],
    }),
    getPositions: build.query({
      query: () => "/positions",
      providesTags: ["Positions"],
    }),
    getProjects: build.query({
      query: () => "/projects",
      providesTags: ["Projects"],
    }),
    getPublications: build.query({
      query: () => "/publications",
      providesTags: ["Publications"],
    }),
    getScores: build.query({
      query: () => "/scores",
      providesTags: ["Scores"],
    }),
    getVolunteering: build.query({
      query: () => "/volunteering",
      providesTags: ["Volunteering"],
    }),
    getSkills: build.query({
      query: () => "/skills",
      providesTags: ["Skills"],
    }),
  }),
});

export const {
  useGetAwardsQuery,
  useGetCertificationsQuery,
  useGetEducationQuery,
  useGetInternshipsQuery,
  useGetHiringsQuery,
  useGetLanguagesQuery,
  useGetOrganizationsQuery,
  useGetPatentsQuery,
  useGetPositionsQuery,
  useGetProjectsQuery,
  useGetPublicationsQuery,
  useGetScoresQuery,
  useGetSkillsQuery,
  useGetVolunteeringQuery,
} = api;
