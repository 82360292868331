import { React } from "react";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetHiringsQuery } from "state/api";
import { useOutletContext } from "react-router-dom";
import Header from "components/Header";
import SubHeader from "components/SubHeader";

function Hirings() {
  const [isSidebarOpen, setIsSidebarOpen] = useOutletContext();
  const { data, isLoading } = useGetHiringsQuery();
  const monthlyData = data?.hirings?.sortedMonths.map((month, index) => ({
    id: index,
    month: month[0],
    hirings_month: month[1],
  }));
  const yearlyData = data?.hirings?.sortedYears.map((year, index) => ({
    id: index,
    year: year[0],
    hirings_year: year[1],
  }));
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const columns_month = [
    {
      field: "month",
      headerName: "Month",
      flex: 1,
    },
    {
      field: "hirings_month",
      headerName: isNonMobile ? "Hirings" : "#",
      flex: isNonMobile ? 0.5 : 0.25,
    },
  ];

  const columns_year = [
    {
      field: "year",
      headerName: "Year",
      flex: 1,
    },
    {
      field: "hirings_year",
      headerName: isNonMobile ? "Hirings" : "#",
      flex: isNonMobile ? 0.5 : 0.25,
    },
  ];

  return (
    <Box
      display="flex"
      max-width="1100px"
      pl="20px"
      flexDirection="column"
      alignItems="center"
      padding="20px"
    >
      <Header
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        title="Hirings 2023"
      />

      {data || !isLoading ? (
        <Box
          display="flex"
          width="100%"
          gap="10px"
          alignItems="center"
          justifyContent="space-between"
          padding="0px var(--spacing-none, 0px) var(--spacing-2xl, 20px) var(--spacing-none, 0px)"
        >
          <Box sx={{ width: "100%" }}>
            <DataGrid
              loading={isLoading || !data}
              sx={{ color: "#fff", border: "none" }}
              getRowId={(row) => row.month}
              rows={monthlyData || []}
              columns={columns_month}
              hideFooter={true}
              disableColumnFilter={true}
            />
          </Box>
        </Box>
      ) : (
        <CircularProgress sx={{ color: "white" }} />
      )}

      <SubHeader title="Hirings per Year" />

      {data || !isLoading ? (
        <Box
          display="flex"
          width="100%"
          gap="10px"
          alignItems="center"
          justifyContent="space-between"
          padding="0px var(--spacing-none, 0px) var(--spacing-2xl, 20px) var(--spacing-none, 0px)"
        >
          <Box sx={{ width: "100%" }}>
            <DataGrid
              loading={isLoading || !data}
              sx={{ color: "#fff", border: "none" }}
              getRowId={(row) => row.year}
              rows={yearlyData || []}
              columns={columns_year}
              hideFooter={true}
              disableColumnFilter={true}
            />
          </Box>
        </Box>
      ) : (
        <CircularProgress sx={{ color: "white" }} />
      )}
    </Box>
  );
}

export default Hirings;
