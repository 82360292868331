import React from 'react'
import { Box, Typography } from '@mui/material'
import { 
  EmojiEventsOutlined,
  CardMembershipOutlined,
  SchoolOutlined,
  ShowChartOutlined,
  GTranslate,
  PercentOutlined,
  LanguageOutlined,
  ConstructionOutlined,
  LibraryBooksOutlined,
  BookOutlined,
  CorporateFareOutlined,
  GroupsOutlined,
  PersonOutlined,
  HomeOutlined,
} from '@mui/icons-material';
import Feature from './Feature';

function Features() {

  
  return (
    <Box id="datapoints" display="flex" justifyContent="center" width="100%" height="100%" sx={{ backgroundColor: "#fff"}}>

      <Box display="flex" p="96px 32px" maxWidth="1280px" alignItems="flex-start" gap="64px" alignSelf="stretch" flexDirection="column">

        <Box display="flex" flexDirection="column" gap="20px" maxWidth="var(--width-xl, 768px)">
          <Box display="flex" flexDirection="column" gap="12px" maxWidth="var(--width-xl, 768px)">
            <Typography fontSize="16px" color="#1369DD" fontWeight="600">Features</Typography>
            <Typography fontSize="36px" color="#101828" fontWeight="600">Explore detailed profile metrics</Typography>
          </Box>
            <Typography fontSize="20px" color="#101828" fontWeight="400">Figure our what your application profiles need to be to get hired by the most sought after employers in the world.</Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap="20px">
          <Box display="flex" flexDirection="row" alignItems="start" alignContent="flex-start" alignSelf="stretch" flexWrap="wrap" gap="32px">

            <Feature icon={<EmojiEventsOutlined />} title="Awards" text="Which and how many awards to peers in your target industry have?" />
            <Feature icon={<CardMembershipOutlined />} title="Certificates" text="Which and how many certificates do your peers have in their profiles?" />
            <Feature icon={<SchoolOutlined />} title="Education" text="What are the top recruiting schools for your target industry?" />
            <Feature icon={<ShowChartOutlined />} title="New hirings" text="Historical hiring statistics by month and year to time your application." />
            <Feature icon={<HomeOutlined />} title="Internships" text="The best places for internships and the numbers of total internships." />
            <Feature icon={<CorporateFareOutlined />} title="Organizations" text="Find organizations to meet your future peers and get to know them." />
            <Feature icon={<PersonOutlined />} title="Career Path" text="How long does it take to get promoted for each career step?" />
            <Feature icon={<GroupsOutlined />} title="Projects" text="What kind of projects did your peers work on in the past?" />
            <Feature icon={<LibraryBooksOutlined />} title="Publications" text="What kind of publications did your peers publish in their profiles?" />
            <Feature icon={<ConstructionOutlined />} title="Skills" text="Which skills do your peers have and what are the most relevant for employers?" />
            <Feature icon={<PercentOutlined />} title="Test Scores" text="Find out the average GMAT and TOEFL Score of your target industry." />
            <Feature icon={<LanguageOutlined />} title="Volunteering" text="Which volunteering experiences are your peers doing?" />
            <Feature icon={<GTranslate />} title="Languages" text="How many languages are your peers speaking on average and what are the polular languages?" />
            <Feature icon={<BookOutlined />} title="Patents" text="How many people hold patents for inventions and what kind of patents are there?" />

          </Box>

        </Box>

      </Box>

    </Box>
    

  )
}

export default Features