import React from 'react'
import { Box, Typography } from '@mui/material'

function Feature(props) {
  return (
    <Box display="flex" gap="20px" flexDirection="column" minWidth="320px" maxWidth="384px" flex="1 0 0" alignItems="flex-start">
        <Box display="flex" width="48px" height="48px" p="12px" justifyContent="center" alignItems="center" sx={{borderRadius: "10px", border: "1px solid #EAECF0", boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}>
            {props.icon}
        </Box>
        <Box display="flex" gap="8px" flexDirection="column">
            <Typography fontSize="20px" color="#101828" fontWeight="600">{props.title}</Typography>
            <Typography fontSize="16px" color="#101828" fontWeight="400">{props.text}</Typography>
        </Box>
    </Box>
  )
}

export default Feature