import React from 'react';
import { 
    Box, 
    Drawer, 
    List, 
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme,
    Typography,
} from '@mui/material';
import { 
    Close,
    HomeOutlined,
    ArrowBack,
    EmojiEventsOutlined,
    CardMembershipOutlined,
    SchoolOutlined,
    ShowChartOutlined,
    GTranslate,
    PercentOutlined,
    LanguageOutlined,
    ConstructionOutlined,
    LibraryBooksOutlined,
    BookOutlined,
    CorporateFareOutlined,
    GroupsOutlined,
    PersonOutlined,
 } from '@mui/icons-material';
 import { useEffect, useState } from 'react';
 import { Navigate, useLocation,useNavigate } from 'react-router-dom';
 import logo from "assets/logoCareerIQ.svg";

const navItems = [
    {
        text: "Awards",
        icon: <EmojiEventsOutlined />
    },
    {
        text: "Certifications",
        icon: <CardMembershipOutlined />
    },
    {
        text: "Education",
        icon: <SchoolOutlined />
    },
    {
        text: "Hirings",
        icon: <ShowChartOutlined />
    },
    {
        text: "Internships",
        icon: <HomeOutlined />
    },
    {
        text: "Languages",
        icon: <GTranslate />
    },
    {
        text: "Organizations",
        icon: <CorporateFareOutlined />
    },
    {
        text: "Patents",
        icon: <BookOutlined />
    },
    {
        text: "Positions",
        icon: <PersonOutlined />
    },
    {
        text: "Projects",
        icon: <GroupsOutlined />
    },
    {
        text: "Publications",
        icon: <LibraryBooksOutlined />
    },
    {
        text: "Skills",
        icon: <ConstructionOutlined />
    },
    {
        text: "Test-Scores",
        icon: <PercentOutlined />
    },
    {
        text: "Volunteering",
        icon: <LanguageOutlined />
    }
]

const Sidebar = ({
    drawerWidth,
    isNonMobile,
    isSidebarOpen,
    setIsSidebarOpen
}) => {
    const { pathname } = useLocation(); //grab the current path
    const [ active, setActive ] = useState("");
    const navigate = useNavigate(); //from react => use to navigate to other pages
    const theme = useTheme();

    useEffect(() => {                           //every time our url changes we set active to the new url
      setActive(pathname.substring(1));         //remove the first string ("/") and set to new active state
    }, [pathname])                              //dependency => only execute useEffect when the "pathname" variable changes

    return (
    <Box>
        {isSidebarOpen && (
            <Drawer 
                open={isSidebarOpen}
                onClose={() => setIsSidebarOpen(false)}
                variant="persistent"
                anchor="left"
                sx={{
                    width: drawerWidth,
                    "& .MuiDrawer-paper": {
                        color: theme.palette.primary.main,
                        backgroundColor: "#fff",
                        boxSizing: "border-box",
                        borderWidth: isNonMobile ? 0 : "2px",
                        width: drawerWidth,
                        position: isNonMobile ? "fixed" : ""
                    }
                }}>
                    <Box width={1} display="Flex" justifyContent="start" flexDirection="column" gap="10px" pt="var(--spacing-4xl, 32px);">
                            
                        <Box display="Flex" alignItems="center" justifyContent="space-between" flexDirection="row" pl="var(--spacing-2xl, 1.5rem)" pr="var(--spacing-3xl, 1.5rem)" pb="8px;" alignSelf="stretch">
                            <Typography display="flex" alignItems="center" justifyContent="flex-start" variant='h5' fontWeight="bold" color={theme.palette.secondary.main} pl="12px;">
                                <img src={logo} alt="CareerIQ Logo" />
                            </Typography>
                            {!isNonMobile && (
                            <ListItemButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                <ListItemIcon sx={{ justifyContent: "end" }}>
                                    <Close />
                                </ListItemIcon>
                            </ListItemButton>
                            )}
                        </Box>
                        
                        

                        <Box display="Flex" flexDirection="column" pl="var(--spacing-2xl, 1.5rem)" pr="var(--spacing-3xl, 1.5rem)" gap="var(--spacing-xs, 0.25rem);">
                            <List disablePadding={true}>
                                <ListItem disableGutters={true}>
                                    <Typography className=".menu-header" fontWeight={600} pl="16px;">
                                        Menu
                                    </Typography>
                                </ListItem>
                                <ListItem disablePadding={true}>
                                    <ListItemButton 
                                        sx={{ backgroundColor: "#fff", color: "#000" }}
                                        href='/'
                                        >
                                        <ListItemIcon sx={{ minWidth: "40px;" }}>
                                          <ArrowBack />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Home
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box>

                        

                        <Box display="Flex" flexDirection="column" pl="var(--spacing-2xl, 1.5rem)" pr="var(--spacing-3xl, 1.5rem)" pb="var(--spacing-3xl, 1.5rem)" gap="var(--spacing-xs, 0.25rem);">
                            <List disablePadding={true}>
                                <ListItem disableGutters={true}>
                                    <Typography className=".menu-header" fontWeight={600} pl="16px;">
                                        Consulting MBB Germany
                                    </Typography>
                                </ListItem>
                                {navItems.map(({ text, icon }) => {
                                const lctext = text.toLowerCase();
                                return (
                                <ListItem key={text} disablePadding={true}>
                                    <ListItemButton 
                                        onClick={() => { 
                                            navigate(`/${lctext}`);  // navigate to URL
                                            setActive(lctext);      // set page to active for color highlighting
                                        }}
                                        sx={{
                                            backgroundColor: active === lctext 
                                            ? "#d0e4ff"
                                            : "#fff"
                                        }}
                                        >
                                        <ListItemIcon sx={{ minWidth: "40px;" }}>
                                        {icon}
                                        </ListItemIcon>
                                        <ListItemText primary={text}>
                                            {text}
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                )
                            })}
                            </List>
                        </Box>
                    </Box>

                </Drawer>
        )}
    </Box>
  )
}

export default Sidebar