import { React } from "react";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import { Tag, Functions, Percent } from "@mui/icons-material";
import DataCard from "components/DataCard";
import { DataGrid } from "@mui/x-data-grid";
import { useGetVolunteeringQuery } from "state/api";
import { useOutletContext } from "react-router-dom";
import Header from "components/Header";
import SubHeader from "components/SubHeader";

function Volunteering() {
  const [isSidebarOpen, setIsSidebarOpen] = useOutletContext();
  const { data, isLoading } = useGetVolunteeringQuery();
  const top = data?.volunteering?.topVolunteers;
  console.log("data", data);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "frequency",
      headerName: isNonMobile ? "Frequency" : "#",
      flex: isNonMobile ? 0.5 : 0.25,
    }
  ];

  return (
    <Box
      display="flex"
      max-width="1100px"
      pl="20px"
      flexDirection="column"
      alignItems="center"
      padding="20px"
    >
      <Header
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        title="Volunteering"
      />

      {data || !isLoading ? (
        <Box
          display="flex"
          width={isNonMobile ? "100%" : "100%"}
          flexDirection={isNonMobile ? "row" : "column"}
          gap="10px"
          alignItems="center"
          justifyContent="space-between"
          padding="10px var(--spacing-none, 0px) var(--spacing-2xl, 20px) var(--spacing-none, 0px)"
        >
          <DataCard
            icon={<Tag sx={{ color: "#fff" }} />}
            title="Number of profiles with Volunteering"
            value={data.volunteering.totalVolunteers}
          />
          <DataCard
            icon={<Percent sx={{ color: "#fff" }} />}
            title="Share of Profiles with Volunteering"
            value={data.volunteering.shareVolunteers + "%"}
          />
          <DataCard
            icon={<Functions sx={{ color: "#fff" }} />}
            title="Average Projects per Volunteering"
            value={data.volunteering.averageVolunteers}
          />
        </Box>
      ) : (
        <CircularProgress sx={{ color: "white" }} />
      )}

      <SubHeader title="Top Volunteering Organizations" />

      {data || !isLoading ? (
        <Box
          display="flex"
          width="100%"
          gap="10px"
          alignItems="center"
          justifyContent="space-between"
          padding="0px var(--spacing-none, 0px) var(--spacing-2xl, 20px) var(--spacing-none, 0px)"
        >
          <Box sx={{ width: "100%" }}>
            <DataGrid
              loading={isLoading || !data}
              sx={{ color: "#fff", border: "none" }}
              getRowId={(row) => row.name}
              rows={top || []}
              columns={columns}
              hideFooter={true}
              disableColumnFilter={true}
            />
          </Box>
        </Box>
      ) : (
        <CircularProgress sx={{ color: "white" }} />
      )}
    </Box>
  );
}

export default Volunteering;
