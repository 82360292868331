import React from 'react'
import { Box, Typography } from '@mui/material'
import DataSet from './DataSet'
import Bain from "assets/bain_logo.png"
import BCG from "assets/bcg_logo.png"
import McKinsey from "assets/mckinsey_logo.png"
import Goldman from "assets/goldman_logo.png"
import MStanley from "assets/mstanley_logo.png"
import JPMorgan from "assets/jpmorgan_logo.png"

function DataSets() {

  const enabled = true;
  const disabled = false;

  return (
    <Box id="datasets" display="flex" justifyContent="center" width="100%" height="100%" sx={{ backgroundColor: "#fff"}}>

      <Box display="flex" p="96px 32px 0px 32px" maxWidth="1280px" alignItems="flex-start" gap="64px" alignSelf="stretch" flexDirection="column">

        <Box display="flex" width="100%" flexDirection="column" gap="20px" maxWidth="var(--width-xl, 768px)">
          <Box display="flex" flexDirection="column" gap="12px" maxWidth="var(--width-xl, 768px)">
            <Typography fontSize="16px" color="#1369DD" fontWeight="600">Dataset</Typography>
            <Typography fontSize="36px" color="#101828" fontWeight="600">Explore our datasets</Typography>
          </Box>
            <Typography fontSize="20px" color="#101828" fontWeight="400">Spend smarter, lower your bills, get cashback on everything you buy, and unlock credit to grow your business.</Typography>
        </Box>

        <Box display="flex" gap="20px" flexWrap="wrap">
          <DataSet logo_1={Bain} logo_2={BCG} logo_3={McKinsey} button={enabled} title="MBB Consulting Germany" date="August 2023" country="Germany" profiles="5.000"/>
          <DataSet logo_1={Goldman} logo_2={MStanley} logo_3={JPMorgan} button={disabled} title="Investment Banking Germany" date="Coming soon" country="Germany" profiles="4.000"/>
        </Box>

      </Box>

    </Box>
  )
}

export default DataSets