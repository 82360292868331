import React from 'react'
import { Box, Card, Typography } from '@mui/material'

function DataCard(props) {
  return (
    <Card sx={{ minWidth: "216px", width: "100%", padding: "20px" }}>
      <Box display="flex" alignItems="flex-start" flexDirection="column" gap="12px">
      <Box display="flex" width="40px" height="40px" padding="10px" justifyContent="center" alignItems="10px" sx={{ borderRadius: "var(--radius-md, 8px)", background: "var(--colors-background-bg-brand-solid, #1369DD)" }}>
        {props.icon}
      </Box>
      <Box display="flex" alignSelf="stretch" alignItems="flex-start" flexDirection="column" gap="2px">
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>{props.title}</Typography>
        <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>{props.value}</Typography>
      </Box>
      </Box>
    </Card>
  )
}

export default DataCard;