import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useMediaQuery } from "@mui/material";

function DataSet(props) {

  const isSmallScreen = useMediaQuery("(max-width: 1000px)");

  return (
    <Box display="flex" gap="24px" alignItems="flex-start">
          <Box display="flex" minWidth="320px" maxWidth="595px" flexDirection="column" alignItems="flex-start">
            <Box display="flex" height="100%" alignItems="flex-start" border="1px solid #EAECF0" borderRadius="12px">
              <Box display="flex" height="100%" gap="24px" p={ isSmallScreen ? "var(--spacing-3xl, 12px)" : "var(--spacing-3xl, 24px)"} flexDirection="column" alignItems="flex-start">
                <Box display="flex" gap="24px" alignItems="flex-start" height="100%">
                  <Box height="72px" flex="1 0 0" alignItems="center" display="flex" p={ isSmallScreen ? "5px" : "24px"} border="1px solid #EAECF0" borderRadius="12px" justifyContent="center">
                    <img style={{maxWidth: '100%', height: '56px', objectFit: 'contain', objectPosition: 'center'}} alt="logo" src={props.logo_1} />
                  </Box>
                  <Box height="72px" flex="1 0 0" alignItems="center" display="flex" p={ isSmallScreen ? "5px" : "24px"} border="1px solid #EAECF0" borderRadius="12px" justifyContent="center">
                    <img style={{maxWidth: '100%', height: '56px', objectFit: 'contain', objectPosition: 'center'}} alt="logo" src={props.logo_2} />
                  </Box>
                  <Box height="72px" flex="1 0 0" alignItems="center" display="flex" p={ isSmallScreen ? "5px" : "24px"} border="1px solid #EAECF0" borderRadius="12px" justifyContent="center">
                    <img style={{maxWidth: '100%', height: '50px', objectFit: 'contain', objectPosition: 'center'}} alt="logo" src={props.logo_3} />
                  </Box>
                </Box>
                <Box>
                  <Typography fontSize="16px" color="#101828" fontWeight="600">{props.title}</Typography>
                  <Typography fontSize="14px" color="#101828" fontWeight="400">{props.country}</Typography>
                </Box>
                <Box>
                  <Typography fontSize="30px" color="#101828" fontWeight="600">+{props.profiles} Profiles</Typography>
                </Box>
                <Box display="flex" minHeight="41px" justifyContent="space-between" alignItems="center" width="100%">
                  <Typography fontSize="14px" color="#101828" fontWeight="400">{props.date}</Typography>
                  {props.button && (
                    <Button variant="contained" href='/awards' size="large" sx={{ backgroundColor: "#1369DD", boxShadow: "none", borderRadius: "8px", border: "1px solid #D0D5DD", color: "#fff", textTransform: "none" }}>View Dataset</Button>
                  )
                  }
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
  )
}

export default DataSet