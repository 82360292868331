import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";

function SubHeader(props) {
  
  const isNonMobile = useMediaQuery("(min-width: 600px)"); //true or false boolean depending on screen size
  const varFont = isNonMobile ? "34px" : "24px";
  
  return (
    <Box
      display="flex"
      width="100%"
      gap="10px"
      alignItems="center"
      justifyContent="space-between"
      padding="10px var(--spacing-none, 0px) var(--spacing-2xl, 20px) var(--spacing-none, 0px)"
    >
      <Typography sx={{ color: "#fff", fontSize: {varFont}, fontWeight: "600" }}>
        {props.title}
      </Typography>
    </Box>
  );
}

export default SubHeader;
