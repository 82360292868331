import { React, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from "components/Sidebar";

function Layout() {
  const isNonMobile = useMediaQuery("(min-width: 600px)"); //true or false boolean depending on screen size
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); 
  const contentWidth = isSidebarOpen ? "calc(100% - 280px)" : "100%"; 
  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%" justifyContent="center">
      <Sidebar 
        isNonMobile={isNonMobile}
        drawerWidth="280px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box 
        flexGrow={1}
        width= { isNonMobile? contentWidth : "auto" } // Apply the content width
        transition="width 0.3s ease" // Add a transition for smooth animation
        overflowX="hidden"
      >
        <Outlet 
          context={[isSidebarOpen, setIsSidebarOpen]}
        />
      </Box>
    </Box>
  )
}

export default Layout