import React from "react";
import { Box, Button, Typography } from "@mui/material";

function Footer() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      gap="20px"
      p="10px"
      alignItems="center"
      height="50px"
      width="100%"
      sx={{ backgroundColor: "#101828" }}
    >
      <Typography color="#fff">Copyright 2023</Typography>
      <a href="https://twitter.com/edmont0x" style={{display: "flex", textDecoration: "none", gap: "12px"}}>
        <Typography color="#fff">Follow me on Twitter @edmont0x</Typography>
      </a>
    </Box>
  );
}

export default Footer;
