import * as React from 'react';
import Box from '@mui/material/Box';
import NavBar from 'components/NavBar';
import Hero from 'components/Hero';
import DataSets from 'components/DataSets';
import Features from "components/Features";
import Footer from 'components/Footer';

function Home() {

  return (
    <Box className="homepage">
      <NavBar />
      <Hero />
      <DataSets />
      <Features />
      <Footer />
    </Box>
  );
}

export default Home;
