import React from "react";
import { Box, IconButton, Typography, Button, useMediaQuery } from "@mui/material";
import { Menu } from "@mui/icons-material";

function Header(props) {

  const isNonMobile = useMediaQuery("(min-width: 600px)"); //true or false boolean depending on screen size
  const varFont = isNonMobile ? "34px" : "24px";

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      padding="20px var(--spacing-none, 0px) var(--spacing-2xl, 20px) var(--spacing-none, 0px)"
    >
      <Box display="flex" alignItems="center" gap="10px">
        <IconButton
          onClick={() => props.setIsSidebarOpen(!props.isSidebarOpen)}
        >
          <Menu sx={{ color: "#fff" }} />
        </IconButton>
        <Typography sx={{ color: "#fff", fontSize: {varFont}, fontWeight: "600" }}>
          {props.title}
        </Typography>
      </Box>
      <Box>
        <Button
          width="100%"
          href="https://community.careeriq.work/"
          variant="contained"
          sx={{
            color: "#fff",
            textTransform: "none",
            backgroundColor: "#1369DD",
            border: "#1369DD",
            '&:hover': {
                  border: '1px solid #d0e4ff !important',
                  color: 'black',
                  backgroundColor: '#d0e4ff'
              }
          }}
        >
          Join Community
        </Button>
      </Box>
    </Box>
  );
}

export default Header;
