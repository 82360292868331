import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#263238',
    },
    secondary: {
      main: '#1565C0',
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      'Roboto'
    ].join(','),
  },

});

